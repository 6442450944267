import queryString from 'query-string';

import config from '../config';
import AjaxHelper from '../helpers/AjaxHelper';

const baseUrl = config.api.prefix;
const applicationsUrl = `${baseUrl}/applications`;
const creditUrl = `${baseUrl}/credit/personal/uc`;
const notificationsUrl = `${baseUrl}/notifications`;
const callbacksUrl = `${baseUrl}/callbacks`;
const transport = AjaxHelper;

export default {
    accept: (id) => transport.get(`${applicationsUrl}/${id}/accept`, 'data'),

    list: (params = {}) => {
        const query = queryString.stringify(params);
        return transport.get(`${applicationsUrl}?${query}`, 'data.applications');
    },

    get: (id) => transport.get(`${applicationsUrl}/${id}`, 'data.application'),

    getViewApplication: (id, type) => transport.get(`${applicationsUrl}/view/${type}/${id}`, 'data'),

    getNewRevisionData: (id, type) => transport.get(`${applicationsUrl}/${type}/${id}/new-revision`, 'data'),

    getUnreadNotificationsCount() {
        return transport.get(`${notificationsUrl}/unread`, 'data.unread_count_result');
    },

    getNotifications(params = {}) {
        const query = queryString.stringify(params);
        return transport.get(`${notificationsUrl}?${query}`, 'data.notifications');
    },

    markNotificationAsRead(notificationId) {
        return transport.patch(`${notificationsUrl}/${notificationId}/state`, { data: { handled: true } });
    },

    markNotificationAsUnread(notificationId) {
        return transport.patch(`${notificationsUrl}/${notificationId}/state`, { data: { handled: false } });
    },

    getUserNotifications(userId, params = {}) {
        const query = queryString.stringify(params);
        return transport.get(`${notificationsUrl}/user/${userId}?${query}`, 'data.notifications');
    },

    getOffers(id, revision) {
        return transport.get(`${applicationsUrl}/${id}/revisions/${revision}/offers`, 'data');
    },

    getSmartOfferId(applicationId, revision) {
        return transport.get(`${applicationsUrl}/private/${applicationId}/revisions/${revision}/smart-offer`, 'data');
    },

    create(application) {
        return transport.post(applicationsUrl, { data: application }, 'data');
    },

    getApplicationStatuses() {
        return transport.get(`${applicationsUrl}/statuses`, 'data');
    },

    put(application, type) {
        return transport.put(`${applicationsUrl}/${type}/${application.id}`, { data: application });
    },

    patchRevision(id, revision, type, application) {
        return transport.patch(`${applicationsUrl}/${type}/${id}/revisions/${revision}`, { data: application });
    },

    delete(application) {
        return transport.delete(`${applicationsUrl}/${application.id}`, 'data');
    },

    getProducts(applicationID, revision) {
        return transport.get(`${applicationsUrl}/${applicationID}/revisions/${revision}/products`, 'data');
    },

    comments(applicationID) {
        return transport.get(`${applicationsUrl}/${applicationID}/comments`, 'data');
    },

    postSalesNote(applicationID, revision, comment) {
        return transport.post(`${applicationsUrl}/${applicationID}/revisions/${revision}/salesnotes`, {
            data: { body: comment },
        });
    },

    campaigns(applicationID) {
        return transport.get(`${applicationsUrl}/${applicationID}/campaigns`, 'data');
    },

    addProductToApplication(applicationID, revision, productId) {
        return transport.post(`${applicationsUrl}/${applicationID}/revisions/${revision}/products`, {
            data: { product_id: productId },
        });
    },

    getFilteredProducts(applicationId, revision) {
        return transport.get(`${applicationsUrl}/${applicationId}/revisions/${revision}/products`, 'data');
    },
    operation(applicationID, applicationType, operation) {
        return transport.post(`${applicationsUrl}/${applicationType}/${applicationID}`, { data: { operation } });
    },
    postOutcome(applicationID, revision, comment) {
        return transport.post(`${applicationsUrl}/${applicationID}/revisions/${revision}/outcomes`, { data: comment });
    },
    postCallback(callBackData) {
        return transport.post(`${callbacksUrl}`, { data: callBackData });
    },
    patchCallback(callbackID, callBackData) {
        return transport.patch(`${callbacksUrl}/${callbackID}`, { data: callBackData });
    },
    fetchQueueUsers(applicationId) {
        return transport.get(`${applicationsUrl}/${applicationId}/callbacks`, 'data.users');
    },

    switchMainApplicant(applicationId, data) {
        return transport.post(
            `${applicationsUrl}/${applicationId}`,
            { data: { operation: 'swap_applicants', data } },
            'data'
        );
    },

    inactivateApplication(applicationId, type, reason) {
        return transport.post(
            `${applicationsUrl}/${type}/${applicationId}`,
            { data: { operation: 'inactivate', data: { reason } } },
            'data'
        );
    },

    splitApplication(applicationID, data) {
        return transport.post(`${applicationsUrl}/${applicationID}`, { data: { operation: 'split', data } }, 'data');
    },

    changeBiddingWindowApplication(applicationID, data) {
        return transport.post(
            `${applicationsUrl}/${applicationID}`,
            { data: { operation: 'update-bidding-window', data } },
            'data'
        );
    },

    reActivateApplication(applicationId, type) {
        return transport.post(
            `${applicationsUrl}/${type}/${applicationId}`,
            { data: { operation: 'reactivate' } },
            'data'
        );
    },

    inactivateRevision(applicationId, revision, type) {
        return transport.post(
            `${applicationsUrl}/${type}/${applicationId}/revisions/${revision}`,
            { data: { operation: 'inactivate' } },
            'data'
        );
    },
    reActivateRevision(applicationId, revision, type) {
        return transport.post(
            `${applicationsUrl}/${type}/${applicationId}/revisions/${revision}`,
            { data: { operation: 'reactivate' } },
            'data'
        );
    },

    updateCreditExpiringDate(creditId, data) {
        return transport.patch(`${creditUrl}/${creditId}`, { data: { expires_at: data } }, 'data');
    },

    // --------------- SIGN ME NOW --------------------

    createAgreement(data) {
        return transport.post(`${baseUrl}/signmenow/agreements`, { data }, 'data');
    },

    getAgreements(responseId) {
        return transport.get(`${baseUrl}/signmenow/agreements?response_id=${responseId}`, 'data');
    },

    cancelAgreement(responseId) {
        return transport.post(`${baseUrl}/signmenow/agreements/${responseId}/cancel`, 'data');
    },

    // ----------------------------------------------

    getFraudReport: (applicationId, type) =>
        transport.post(`${applicationsUrl}/${type}/${applicationId}/fraud-report`, {}, 'data'),
};
